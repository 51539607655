import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, ListItemButton, Divider, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';

import SettingsIcon from '@mui/icons-material/Settings';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import WineBarIcon from '@mui/icons-material/WineBar';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import HomeIcon from '@mui/icons-material/Home';

// Styla en ikon för att kunna rotera den
const RotatingIcon = styled('div')(({ rotate }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform 0.3s ease-in-out',
  transform: rotate ? 'rotate(180deg)' : 'rotate(0deg)',
}));

function TestDrawer() {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const toggleDrawer = (newState) => {
        setOpen(newState);
    }

    const handleNavigation = (path) => {
        navigate(path);
        toggleDrawer(false);
    }

    return (
        <>
            <div style={{
                position: "absolute",
                top: "0",
                right: "0",
                padding: "10px"
            }}>
                <IconButton onClick={() => toggleDrawer(true)} 
                    sx={{
                        backgroundColor: '#e0e0e0', // Grå cirkel
                        borderRadius: '50%',        // Rund form
                        padding: '12px',            // Ger utrymme runt ikonen
                        '&:hover': {
                        backgroundColor: '#bdbdbd', // Lite mörkare grå vid hover
                        },
                    }}
                >
                    <RotatingIcon rotate={open ? 'true' : 'false'}>
                        {open ? <CloseIcon /> : <MenuIcon />}
                    </RotatingIcon>
                </IconButton>                
            </div>    
            <Drawer 
                open={open} 
                onClose={() => toggleDrawer(false)} 
                sx={{ '& .MuiDrawer-paper': { backgroundColor: '#f0f0f0', width: 300 } }}
            >
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => handleNavigation('/winecellar')}>
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Startsida" />
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => handleNavigation('/winecellar/producers')}>                        
                            <ListItemIcon>
                                <AgricultureIcon />
                            </ListItemIcon>
                            <ListItemText primary="Producenter" />
                        </ListItemButton>                            
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>                        
                            <ListItemIcon>
                                <WineBarIcon />
                            </ListItemIcon>
                            <ListItemText primary="Viner" />
                        </ListItemButton>                            
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemButton>                        
                            <ListItemIcon>
                                <ThermostatIcon />
                            </ListItemIcon>                    
                            <ListItemText primary="Temperatur" />
                        </ListItemButton>                            
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>                        
                            <ListItemIcon>
                                <WaterDropIcon />
                            </ListItemIcon>                    
                            <ListItemText primary="Luftfuktighet" />
                        </ListItemButton>                            
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemButton>                        
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>                    
                            <ListItemText primary="Inställningar" />
                        </ListItemButton>                            
                    </ListItem>
                </List>
            </Drawer>
        </>
    );
}

export default TestDrawer;