import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Pages/Home/Home';
import Winecellar from './Pages/Winecellar/Home/Winecellar';
import Test from './Components/Test/Test';
import Grid from './Components/Grid/Grid';
import Producers from './Pages/Winecellar/Producers/Producers';

// Behös för MUI
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
  return (
    <Router>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/winecellar" element={<Winecellar />} />
          <Route path="/winecellar/producers" element={<Producers />} />          
          <Route path="/test" element={<Test />} />
          <Route path="/grid" element={<Grid />} />          
      </Routes>
    </Router>
  );
}

export default App;
