import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  
  return (
    <div ref={setNodeRef} style={style} >
      <div ref={setActivatorNodeRef} {...attributes} {...listeners}>(FLYTTA)</div> <div>{props.id} - {props.item.name}</div>
    </div>
  );
}

export default SortableItem;