import React from 'react';
import TestDrawer from '../../../Components/Drawer/Drawer';

const fontStyle = { 
    color: "#ffffff",
    fontFamily: "Nerko One, cursive",
    fontWeight: 400,
    fontStyle: "normal"
}

function Producers() {
   return (
    <div className="App" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", paddingTop: "60px" }}>
      <h1 style={fontStyle}>Producenter</h1>

      <div>
        <TestDrawer />
      </div>
    
      <div style={{height: "15px"}}></div>      
   </div>
  );
}

export default Producers;