import React, { useState } from 'react';
import { Button, Box, Typography, Modal, Tab, Tabs } from '@mui/material';

function TestModal() {
    const [open, setOpen] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
      };    

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: "250px",
        width: "80%",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px',
      };    

    return (
        <div>
            <Button onClick={handleOpen} variant="contained" color="success">Specifikt vin</Button>
            <Modal open={open} onClose={handleClose}> 
                <Box sx={style}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleTabChange}>
                            <Tab label="Allmän info" />
                            <Tab label="Producent" />
                            <Tab label="Druva" />
                        </Tabs>
                        <CustomTabPanel value={tabValue} index={0}>
                            Här ligger allmän info om vinet...
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={1}>
                            Producent
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={2}>
                            Druva
                        </CustomTabPanel>                    
                    </Box>

                    <Button onClick={handleClose} variant="contained" sx={{ mt: 2 }}>
                        Stäng
                    </Button>
                </Box>

      </Modal>

    </div>);
}

/*
                    <Typography id="modal-title" variant="h6" component="h2">
                        Information
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        Här kommer lite extra information, i en modal.
                    </Typography>
*/

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    if (value === undefined || index === undefined) {
      // Kontrollera att value och index inte är undefined
      return null;
    }
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

export default TestModal;