import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import TestDrawer from '../../../Components/Drawer/Drawer';
import InfoModal from '../../../Components/InfoModal';
import CircularProgress from '@mui/material/CircularProgress';
import './Winecellar.css';
import { styled } from '@mui/system';

function Winecellar() {
 
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);


  const numberFontStyle = { 
      color: "#000000",
      fontFamily: "Nerko One, cursive",
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: "100px", 
      textAlign: "center"      
  }

  const x = 8; // Antalet kolumner
  const y = 10; // Antalet rader

  // Flex-container som hanterar rutnätet
  const FlexContainer = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    width: '90%',  
    height: `${50*y}px`,
    paddingTop: "30px",
  });

  // Flex-item som representerar varje ruta
  const FlexItem = styled('div')({
    width: `${100 / x}%`,
    height: `${100 / y}%`,
    backgroundColor: 'rgba(224, 224, 224, 0.15)',  // 50% transparent grå bakgrund
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    borderBottom: '0.5px solid #ffffff',
    boxSizing: 'border-box' // För att se till att paddingen inte påverkar rutornas storlek
  });

  const Circle = styled('div')({
    width: '50px',             // Nästan fyller hela rutan
    height: '90%',            // Samma höjd som bredd för att skapa en cirkel
    backgroundColor: '#757575', // Ogenomskinlig grå cirkel
    borderRadius: '50%',      // Gör formen till en cirkel
    position: 'relative',     // Absolut positionering för att placera cirkeln i mitten
    transition: 'background-color 0.3s ease', // Smooth transition for color change
    '&:hover': {
        backgroundColor: '#ff6347', // Byter färg till t.ex. röd-orange (Tomato) på hover
    },
  });  

  const handleBottleClick = (shelfPrefix, index) => {
    setModalInfo(`${shelfPrefix} - ${index}`);
    setShowModal(true);
  }

  const createGrid = (shelfPrefix) => {
    const items = [];
    for (let i = 0; i < x * y; i++) {
      items.push(<FlexItem key={i}><Circle onClick={() => handleBottleClick(shelfPrefix, i)} /></FlexItem>);
    }
    return items;
  };

  useEffect(() => {
      //Mock-mode?
      const useMockMode = false;

      //Change background
      const backgroundElement = document.getElementById('background');
      backgroundElement.classList.add('winecellar-background');

      if (useMockMode) {
        setData(JSON.parse("{\"deviceId\":\"cc24be\",\"batteryLevel\":98,\"temperature\":19.25,\"humidity\":77.5}"));
        setLoading(false);
      }
      else {
        fetch('https://pjcnybmc4h.execute-api.eu-north-1.amazonaws.com/prod/shelly/get-status')
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            setData(data);
            setLoading(false);
        })
        .catch((error) => {
            setError(error);
            setLoading(false);
        });
      }
  }, []);

  return (
    <div className="App" style={{alignItems: "center", paddingTop: "60px"}}>
      <Container>
        <Row>
            <Col xs={12} sm={6} md={6} lg={6}>
              <div className="InfoContainer" style={{paddingTop: "15px"}}>
                <Container>
                <Row className="justify-content-center">
                  <Col xs={12} sm={8} md={9} lg={9}>
                    { loading && <div style={{"fontSize": "30px", "textAlign": "center"}}><CircularProgress /></div> }
                    { error && <p>Error: {error.message}</p> }
                    { !loading && !error && 
                      <p style={numberFontStyle}>
                        {data.temperature}&deg;
                      </p>
                    }
                    </Col>
                  </Row>
                </Container>
              </div>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <div className="InfoContainer" style={{paddingTop: "15px"}}>
              <Container>
                <Row className="justify-content-center">
                  <Col xs={12} sm={8} md={9} lg={9}>
                  { loading && <div style={{"fontSize": "30px", "textAlign": "center"}}><CircularProgress /></div> }
                  { error && <p>Error: {error.message}</p> }
                  { !loading && !error &&  
                    <p style={numberFontStyle}>
                      {data.humidity}%   
                    </p>
                  }
                  </Col>            
                </Row>
              </Container>
            </div>

            <div>
              <TestDrawer />
            </div>


          </Col>
        </Row>

        <Row style={{ paddingTop: "20px" }}></Row>

        <Row>
          <Col xs={12} sm={6} md={6} lg={6} style={{ display: "flex", justifyContent: "center"}}>
            <FlexContainer>
              {createGrid("Vänster")}
            </FlexContainer>                
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} style={{ display: "flex", justifyContent: "center"}}>
            <FlexContainer>
                {createGrid("Höger")}
            </FlexContainer>                
          </Col>
        </Row>

      </Container>
      
      <InfoModal open={showModal} setOpen={setShowModal} modalInfo={modalInfo} />
      <div style={{height: "15px"}}></div>      
   </div>
  );
}

export default Winecellar;