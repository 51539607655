import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {DndContext} from '@dnd-kit/core';
import {SortableContext, verticalListSortingStrategy, arrayMove} from '@dnd-kit/sortable';
import SortableItem from './SortableItem';

function Home() {
  
  const [items, setItems] = useState([
    {id: 1, name: "hej"}, 
    {id: 2, name: "hej2"},
    {id: 3, name: "hej3"}]);

//    const [items, setItems] = useState([1,2,3]);

const handleDragEnd = (event) => {
  const {active, over} = event;

  if (over && active.id !== over.id) {
    const oldIndex = items.findIndex(item => item.id === active.id);
    const newIndex = items.findIndex(item => item.id === over.id);

    if (oldIndex !== -1 && newIndex !== -1) {
      setItems((items) => {
        const newItems = [...items];
        return arrayMove(newItems, oldIndex, newIndex);
      });
    }
  }
};

  return (
    <div className="App" style={{alignItems: "center"}}>
      <div className="InfoContainer" style={{paddingTop: "15px"}}>
        <Container>
          <Row style={{paddingBottom: "15px"}}>
            <Col xs={12} sm={4} md={3} lg={3} className="text-center">     
            </Col>
            <Col xs={12} sm={8} md={9} lg={9}>
              <h2>falth.nu</h2>
              <p style={{"fontSize": "14px"}}>
                Välkommen - mer info kommer senare. 
              </p>
            </Col>
          </Row>
          <Row>
            <DndContext onDragEnd={handleDragEnd}> 
            <SortableContext items={items.map(item => item.id)} strategy={verticalListSortingStrategy}>
              <div>
              { items.map((item) => (
                <SortableItem key={item.id} id={item.id} item={item} />
              )) }
              </div>
            </SortableContext>
          </DndContext>

          </Row>
        </Container>
      </div>
      <div style={{height: "15px"}}></div>
   </div>
  );
}

export default Home;
